export default {
  install (Vue) {
    Vue.filter('formatPrice', value => {
      if (!value && value !== 0) {
        return ''
      }
      return value.toFixed(2)
    })
  }
}
