export default {
  install(Vue) {
    Vue.directive('img', {
      inserted(el, binding) {
        // el.src = binding.value
        const observer = new IntersectionObserver((entries) => {
          // 遍历
          for (const i of entries) {
            // 如果改元素处于可视区
            if (i.intersectionRatio > 0) {
              // console.log('--binding.value--', i.intersectionRatio + '\n' + binding.value)
              // 获取该元素
              const img = i.target
              // 重新设置src值
              img.src = binding.value
              // 取消对该元素的观察
              observer.unobserve(img)
            }
          }
        })
        // 为 img 标签添加一个观察
        observer.observe(el)
        el.onerror = function () {
          el.src =
            'https://img3.doubanio.com/f/movie/8dd0c794499fe925ae2ae89ee30cd225750457b4/pics/movie/celebrity-default-medium.png'
        }
      }
    })
  }
}
