import {
  Button,
  NavBar,
  Form,
  Field,
  Tabbar,
  TabbarItem,
  Search,
  Swipe,
  SwipeItem,
  Grid,
  GridItem,
  List,
  PullRefresh,
  tag,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  TreeSelect,
  Icon,
  SubmitBar,
  Checkbox,
  CheckboxGroup,
  SwipeCell,
  Card,
  Image as VanImage,
  Cell,
  CellGroup,
  Dialog,
  Empty,
  CouponCell,
  CouponList,
  Toast
} from 'vant'

export default {
  // install 名字固定
  install(Vue) {
    Vue.use(Button)
    Vue.use(NavBar)
    Vue.use(Form)
    Vue.use(Field)
    Vue.use(Tabbar)
    Vue.use(TabbarItem)
    Vue.use(Search)
    Vue.use(Swipe)
    Vue.use(SwipeItem)
    Vue.use(Grid)
    Vue.use(GridItem)
    Vue.use(List)
    Vue.use(PullRefresh)
    Vue.use(tag)
    Vue.use(GoodsAction)
    Vue.use(GoodsActionButton)
    Vue.use(GoodsActionIcon)
    Vue.use(TreeSelect)
    Vue.use(Icon)
    Vue.use(SubmitBar)
    Vue.use(Checkbox)
    Vue.use(CheckboxGroup)
    Vue.use(SwipeCell)
    Vue.use(Card)
    Vue.use(VanImage)
    Vue.use(Cell)
    Vue.use(CellGroup)
    Vue.use(Dialog)
    Vue.use(Empty)
    Vue.use(CouponCell)
    Vue.use(CouponList)
    Vue.use(Toast)
  }
}
