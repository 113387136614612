import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import category from './modules/category'
import createVuexPersistedState from 'vuex-persistedstate'
import cart from './modules/cart'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: { user, category, cart },
  plugins: [
    createVuexPersistedState({
      key: 'vue2-mobile'
    })
  ]
})
