import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '@/store'
// import Layout from '@/views/Layout'
// import Login from '@/views/Login'
// import NotFound from '@/views/NotFound'
// import Home from '@/views/Home'
// import Category from '@/views/Category'
// import Cart from '@/views/Cart'
// import My from '@/views/My'
// import GoodsDetail from '@/views/GoodsDetail'
// import Dialog from '@/views/Dialog'
// 动态引入组件的方式，实现路由懒加载
const Login = () => import('@/views/Login')
const Layout = () => import('@/views/Layout')
// const Home = () => import('@/views/Home')
const Category = () => import('@/views/Category')
const Cart = () => import('@/views/Cart')
const My = () => import('@/views/My')
const GoodsDetail = () => import('@/views/GoodsDetail')
const NotFound = () => import('@/views/NotFound')
const Dialog = () => import('@/views/Dialog')

// 新的+
// const MyCard = () => import('@/views/HomeNew/active1-3/mycard')
// const Active1 = () => import('@/views/HomeNew/active1-3/index')
const MyCard = () => import('@/views/HomeNew/mycard')
const Active1 = () => import('@/views/HomeNew/active1/index')
const Active2 = () => import('@/views/HomeNew/active2/index')
const Active3 = () => import('@/views/HomeNew/active3/index')

const Active4 = () => import('@/views/HomeNew/active4/index')
const redDetail = () => import('@/views/HomeNew/active4/redDetail')
const Active5 = () => import('@/views/HomeNew/active5/index')
const Active6 = () => import('@/views/HomeNew/active6/index')
const Active7 = () => import('@/views/HomeNew/active7/index')
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    // redirect: '/home',
    redirect: '/active1',
    children: [
      {
        path: 'active1',
        name: 'active1',
        meta: {
          title: '抽奖'
        },
        component: Active1
      },
      {
        path: 'active2',
        name: 'active2',
        component: Active2
      },
      {
        path: 'active3',
        name: 'active3',
        component: Active3
      },
      {
        path: 'active4',
        component: Active4
      },
      {
        path: 'redDetail',
        component: redDetail
      },
      {
        path: 'active5',
        component: Active5
      },
      {
        path: 'active6',
        meta: {
          title: '优惠券领取'
        },
        component: Active6
      },
      {
        path: 'active7',
        meta: {
          title: 'zfb优惠券领取'
        },
        component: Active7
      },
      {
        path: 'mycard',
        meta: {
          title: '我的奖品'
        },
        component: MyCard
      },
      // {
      //   path: 'homeOld',
      //   component: Home
      // },
      {
        path: 'category',
        component: Category
      },
      {
        path: 'cart',
        component: Cart
      },
      {
        path: 'my',
        component: My,
        meta: {
          needLogin: true
        }
      }
    ]
  },
  {
    path: '/detail',
    component: GoodsDetail
  },
  // {
  //   path: '/detail/:proid',
  //   component: GoodsDetail
  // },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/dialog',
    name: 'dialog',
    component: Dialog
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]
const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log('to', to)
  const token = Store.state.user.token
  if (to.meta.title) {
    document.title = to.meta.title ? to.meta.title : ''
  }
  if (to.meta.needLogin && !token) {
    // router.push({ path: '/login' }) // 推荐
    // next({ path: '/login' }) // 不推荐 会存在捕捉不到的错误
    router.push({
      name: 'login',
      query: { redirect: to.fullPath } // 登录后再跳回此页面时要做的配置
    })
  } else {
    next()
  }
})
export default router
