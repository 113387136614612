export default {
  namespaced: true,
  state: {
    token: ''
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload
    },
    out(state) {
      state.token = null
    }
  },
  actions: {
    // 登录，获取token
  }
}
