import { getCategoryList } from '@/api/category'
export default {
  namespaced: true,
  state: {
    categoryList: []
  },
  getters: {
    // 左侧菜单
    menu(state) {
      return state.categoryList.map((item) => ({
        cat_id: item.cat_id,
        cat_name: item.cat_name
      }))
    },
    right(state) {
      const res = []
      state.categoryList.forEach((item) => {
        if (!item.children) return false
        res.push({ ...item })
      })
      return res
    }
  },
  mutations: {
    // 拿到分类数据
    setCategoryList(state, val) {
      state.categoryList = val
    }
  },
  actions: {
    // 调接口拿数据
    async getCategory(context) {
      const res = await getCategoryList()
      context.commit('setCategoryList', res.data)
    }
  }
}
