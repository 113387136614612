export default {
  namespaced: true,
  state: {
    cartList: []
  },
  getters: {
    // 总计多少
    count(state) {
      let count = 0
      state.cartList.forEach((element) => {
        if (!element.checked) return
        // console.log(element.num)
        count += element.num
      })
      return count
    },
    // 总价钱
    price(state) {
      let price = 0
      state.cartList.forEach((element) => {
        if (!element.checked) return
        price += element.originprice * element.num
      })
      return price
    }
  },
  mutations: {
    // 添加到购物车
    addToCart(state, goods) {
      console.log('---cart-payload-', goods)
      const product = { ...goods, num: 1, checked: true } // 添加数量字段
      const oldGoods = state.cartList.find(item => item.proid === product.proid)
      if (oldGoods) {
        oldGoods.num += product.num
      } else {
        state.cartList.push(product)
      }
    },
    // 添加
    increNUm(state, payload) {
      const index = state.cartList.findIndex((item) => item.proid === payload)
      //   console.log(index);
      state.cartList[index].num++
    },
    // 减少
    deincreNUm(state, payload) {
      const index = state.cartList.findIndex((item) => item.proid === payload)
      //   console.log(index);
      state.cartList[index].num--
    },
    // 全选
    all(state, payload) {
      state.cartList.forEach((element) => {
        element.checked = payload
      })
    },
    // 单选
    one(state, payload) {
      state.cartList.map((element) => element.proid === payload).checked = !payload
    },
    // 删除
    del(state, payload) {
      let index = -1
      index = state.cartList.findIndex((ele) => ele.proid === payload.proid)
      console.log(index)
      if (index === -1) return
      state.cartList.splice(index, 1)
    }
  },

  actions: {
    // 全选
    asycSelectAll(context, bool) {
      // 传进来的值是false
      context.commit('all', bool)
    },
    // 单选One
    asycOne(context, bool) {
      context.commit('one', bool)
    }
  }
}
