import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css'
// 自己的样式放在第三方后面
import '@/assets/styles/global.scss'
import myVant from '@/plugins/vant'
import Directives from '@/plugins/directives'
import myFilters from '@/plugins/filters'
Vue.use(myVant)
Vue.use(Directives)
Vue.use(myFilters)
Vue.config.productionTip = false
Vue.prototype.$cloud = window.cloud;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
