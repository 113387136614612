import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASEURL
})

instance.interceptors.request.use(
    config => {
        // config.headers['Content-Type'] = 'application/json'
        // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        
        const token = JSON.parse(sessionStorage.getItem('userInfo'))?.token;
        // config.headers["Content-Type"] = "application/json;charset=utf-8";
        if (token) {
          config.headers["satoken"] = token;
        }
        return config
    }, err => {
        return Promise.reject(err)
    }
)

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response.data
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)
export default instance
